import React, {
    useContext,
    useState,
} from 'react';
import {observer} from "mobx-react";
import BlockTitle from "../_BlockTitle";
import {StoreContext} from "../../../../stores/StoreLoader";
import styles from "./styles/AccordionTerrace.module.scss"
import classNames from 'classnames';
import FontAwesome from "../../../utilities/FontAwesome";
import WatsonApi from "../../../../backends/WatsonApi";
import {runInAction} from "mobx";
import NotificationManager from "../../../notifications/NotificationManager";
import AccordionItemEditor from "./AccordionItemEditor";
import AccordionItem from "./AccordionItem";
import {EditDropDownItem} from "../EditDropDown";

const AccordionTerrace = observer((props: {
    blockObj: IAccordionTerrace,
    aboveTheFold: boolean,
}) => {
    const {userStore, gridStore, organizationStore, interfaceStore} = useContext(StoreContext);
    const [reorder, setReorder] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [addingNewAccordionItem, setAddingNewAccordionItem] = useState(false);
    const accordionTerraceContainerClassName = classNames({
        [styles.container]: true,
    })

    const [accordionList, setAccordionList] = useState(props.blockObj.json_data.accordion || [])
    const reorderClassName = classNames({
        [styles.reorder]: reorder,
    });
    const accordionListcClassName = classNames({
        [styles.accordionList]: true,
        [styles.accordionListBackgroundImage]: !!organizationStore.currentOrganization.backgroundImage,
    });

    function _setEditing(value) {
        setCanEdit(value);
        setAddingNewAccordionItem(false);
        setReorder(false);
    }

    const handleSave = async () => {
        try {
            const client = await WatsonApi();
            const response = await client.apis.organizations.organizations_terraces_partial_update({
                organization_pk: props.blockObj.organization_id,
                id: props.blockObj.id,
                data: {
                    json_data: {accordion: accordionList},
                },
            })
            runInAction(() => {
                const updatedTerrace = JSON.parse(response.data);
                gridStore.blocks.set(updatedTerrace.id, updatedTerrace);
            });
            NotificationManager.success("Terrace successfully saved!");
            setAddingNewAccordionItem(false);
        } catch (e) {
            NotificationManager.error("Something went wrong! Please try again.");
        }
    }
    const handleCancel = () => {
        setAddingNewAccordionItem(false);
    }

    const [isDragging, setIsDragging] = useState(false);

    const reorderAccordionDropDownItem = {
        title: 'Toggle Reordering',
        icon: <FontAwesome prefix={"fas"} fixedWidth name={'fa-arrows-up-down'} ariaHidden
                           className={reorderClassName}/>,
        onClick: () => setReorder(v => !v),
        hideButtonSelector: true,
    }

    const accordionTerraceDropDownItems: EditDropDownItem[] = [
        {
            title: 'Add Message',
            icon: <FontAwesome prefix={'fas'} fixedWidth name={"fa-plus"}/>,
            onClick: () => setAddingNewAccordionItem(true),
        },
    ]

    if (accordionList?.length > 1) {
        accordionTerraceDropDownItems.push(reorderAccordionDropDownItem)
    }

    return (
        <article>
            <BlockTitle
                blockObj={props.blockObj}
                aboveTheFold={props.aboveTheFold}
                editing={canEdit}
                setEditing={_setEditing}
                allowSave={true}
                dropDownItems={accordionTerraceDropDownItems}
                showViewMore={false}
            />
            <div className={accordionTerraceContainerClassName}>
                {accordionList?.length > 0 && !addingNewAccordionItem && <ul className={accordionListcClassName}>
                    {accordionList?.map((accordionItem, index) => {
                        return <AccordionItem key={index}
                                              reorder={reorder}
                                              item={accordionItem}
                                              accordionList={accordionList}
                                              index={index}
                                              handleSave={handleSave}
                                              blockObj={props.blockObj}
                                              handleCancel={handleCancel}
                                              isDragging={isDragging}
                                              setIsDragging={setIsDragging}
                                              setAccordionList={setAccordionList} />
                    })}
                </ul>
                }
                {addingNewAccordionItem &&
                    <div className={styles.accordionItem}>
                        <AccordionItemEditor
                            blockObj={props.blockObj}
                            index={accordionList?.length || 0}
                            item={
                                {
                                    "title": "",
                                    "content": "",
                            }
                            }
                            handleSave={handleSave}
                            handleCancel={handleCancel}
                            accordionList={accordionList}
                            setAccordionList={setAccordionList}
                        />
                    </div>
                }
            </div>
        </article>
    );
})

export default AccordionTerrace;
